<template>
  <div class="background-image wrapper"
       v-bind:style="{backgroundImage: 'url(' + getBackgroundImageUrl + ')'}"
  >
    <div class="container-lg container-fluid">
      <h2 class="txt-aln-center" v-html="releaseData.title"/>
      <div class="artwork-container row gut-0">
        <div class="artwork col-lg-4 col-sm-6">
          <div class="artwork-img bg" v-bind:style="{backgroundImage: 'url(' + getArtworkImageUrl + ')'}"/>
        </div>
      </div>
      <div class=" link-wrapper link-container">
        <a :href="link.url" target="_blank" v-for="(link, index) in releaseData.links" :key="index" class="item">
          <div class="image" v-bind:style="{backgroundImage: 'url(' + getLinkImageUrl(link.image) + ')'}"/>
          <div class="content">
            <div class="title" v-html="link.title"/>
            <div class="info" v-html="link.info"/>
          </div>
        </a>
      </div>
      <div class="text-container">
        <div class="text" v-html="releaseData.credits"/>
      </div>
    </div>
  </div>
</template>

<script>
import {cpFullStorageUrl, cpGetContentItem} from "@/api/cockpit";
import {removeFirstLastDashFromPath} from "@/main";

export default {
  name: "ReleaseView",
  data() {
    return {
      releaseData: {
        title: '',
        links: '',
        credits: '',
        artwork: {
          path: ''
        },
      },
    };
  },
  methods: {
    getLinkImageUrl(image) {
      if (image) {
        return cpFullStorageUrl(image.path);
      }
      return '';
    }
  },
  computed: {
    getBackgroundImageUrl() {
      return cpFullStorageUrl(this.$store.getters.getGlobalAttributeBackgroundImage.path);
    },
    getArtworkImageUrl() {
      return cpFullStorageUrl(this.releaseData.artwork.path);
    }

  },
  async created() {
    let releaseSlug = removeFirstLastDashFromPath(this.$route.path);
    cpGetContentItem(releaseSlug).then(data => {
      this.releaseData = data;
    })
  },
}
</script>

<style scoped>
.background-image {
  /*filter: blur(8px);*/
  /*-webkit-filter: blur(8px);*/
  background-size: cover;
}

.artwork-container {
  display: flex;
  justify-content: center;

}

.artwork > .artwork-img {
  border: solid var(--color4) 3px;
  /*width: 400px;*/
  /*height: 400px;*/
  padding-top: 100%;
}

.link-container {
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 756px;
}

.text-container {
}

.text-container > .text {
  border: solid var(--color4) 3px;


  max-width: calc(400px + 1rem + 1rem);
  text-align: center;
  color: var(--color4);
  margin: auto;
  background: var(--color3-75);
  padding: 1rem;

  text-shadow: 0px 0px 10px var(--color4);
  /*-webkit-text-stroke-width: var(--stroke-width-thin);*/
  /*-webkit-text-stroke-color: var(--color4);*/

}

.text-container > .text p {
  margin-bottom: 0;
}
</style>
